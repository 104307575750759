import {
  APP_INITIALIZER,
  enableProdMode,
  importProvidersFrom
} from '@angular/core';
import { environment } from './environments/environment';
import { APP_CONFIG, AppConfig } from './app/common/app-config';
import posthog from 'posthog-js';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { TokenInterceptor } from './app/shared/token.interceptor';
import { ETagInterceptor } from './app/shared/etag.interceptor';
import { API_BASE_URL } from './clients/apiClients';
import { SignalrService } from './app/shared/signalr.service';
import { MasterDataService } from './shared/master-data.service';
import { HelpService } from './app/shared/help.service';
import { PendingChangesGuard } from './app/shared/pending-changes.guard';
import { AuthGuard } from './app/shared/auth-guard.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { APP_ROUTES } from './app/app.routing';
import { TableModule } from 'primeng/table';
import { ChipModule } from 'primeng/chip';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { TooltipModule } from 'primeng/tooltip';
import { provideStore } from '@ngxs/store';
import { AppState } from './shared/app.state';
import { MasterDataState } from './shared/master-data.state';
import { CheckboxModule } from 'primeng/checkbox';
import { MenuModule } from 'primeng/menu';
import { MessageModule } from 'primeng/message';
import { PanelModule } from 'primeng/panel';
import { FileUploadModule } from 'primeng/fileupload';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { QRCodeModule } from 'angularx-qrcode';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { FocusTrapModule } from 'primeng/focustrap';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { AppComponent } from './app/app.component';
import { provideRouter, Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { StorageOption, withNgxsStoragePlugin } from '@ngxs/storage-plugin';

function masterDataInitializer(masterDataService: MasterDataService) {
  return () => masterDataService.initialize();
}
function helpInitializer(helpService: HelpService) {
  return () => helpService.initialize();
}

function initTooling(router: Router, appConfig: AppConfig) {
  return () => {
    let appInsights: ApplicationInsights | undefined;
    const angularPlugin = new AngularPlugin();
    if (appConfig.applicationInsights) {
      appInsights = new ApplicationInsights({
        config: {
          connectionString: appConfig.applicationInsights,
          extensions: [angularPlugin],
          enableCorsCorrelation: true,
          disableCookiesUsage: true,
          enableRequestHeaderTracking: true,
          enableResponseHeaderTracking: true,
          extensionConfig: {
            [angularPlugin.identifier]: { router: router }
          }
        }
      });
      appInsights.loadAppInsights();
    }
    const handlePosthogCookieConsent = (consent: boolean) => {
      posthog.set_config({
        persistence: consent ? 'localStorage+cookie' : 'memory'
      });
    };
    const handleAppInsightsCookieConsent = (consent: boolean) => {
      if (appInsights !== undefined) {
        appInsights.getCookieMgr().setEnabled(consent);
      }
    };

    window.addEventListener('CookiebotOnConsentReady', () => {
      handlePosthogCookieConsent(Cookiebot.consent.marketing);
      handleAppInsightsCookieConsent(Cookiebot.consent.statistics);
    });
  };
}

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

const configUrl = environment.production
  ? '/assets/config.json'
  : '/assets/config.local.json';

fetch(configUrl)
  .then((response) => response.json())
  .then((config) => {
    posthog.init(config.postHogKey, {
      api_host: 'https://mon.cybersort.io',
      ui_host: 'https://eu.posthog.com',
      persistence: 'memory',
      capture_pageview: false,
      enable_recording_console_log: true
    });

    if (environment.production) {
      enableProdMode();
    }
    const providers = [
      { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
      { provide: APP_CONFIG, useValue: config }
    ];
    bootstrapApplication(AppComponent, {
      providers: [
        importProvidersFrom(
          BrowserModule,
          TableModule,
          ChipModule,
          ButtonModule,
          RippleModule,
          TooltipModule,
          environment.plugins,
          CheckboxModule,
          MenuModule,
          MessageModule,
          PanelModule,
          FileUploadModule,
          ConfirmDialogModule,
          FormsModule,
          InputTextModule,
          ReactiveFormsModule,
          QRCodeModule,
          DialogModule,
          ToastModule,
          FocusTrapModule,
          CardModule,
          DropdownModule
        ),
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ETagInterceptor,
          multi: true
        },
        {
          provide: API_BASE_URL,
          useFactory: (config: AppConfig) => config.apiUrl,
          deps: [APP_CONFIG]
        },
        ...environment.providers,
        ...providers,
        {
          provide: APP_INITIALIZER,
          useFactory: masterDataInitializer,
          deps: [MasterDataService],
          multi: true
        },
        {
          provide: APP_INITIALIZER,
          useFactory: helpInitializer,
          deps: [HelpService],
          multi: true
        },
        {
          provide: APP_INITIALIZER,
          useFactory: initTooling,
          deps: [Router, APP_CONFIG],
          multi: true
        },
        PendingChangesGuard,
        AuthGuard,
        MessageService,
        ConfirmationService,
        MasterDataService,
        {
          provide: SignalrService,
          useClass: SignalrService
        },
        HelpService,
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
        provideStore([AppState, MasterDataState], {
          developmentMode: !environment.production,
          compatibility: {
            strictContentSecurityPolicy: true
          }
        }),
        withNgxsStoragePlugin({
          keys: [],
          storage: StorageOption.SessionStorage
        }),
        provideRouter(APP_ROUTES)
      ]
    })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  });
