export class ChatQuery {
  static readonly type = '[Chat] Query';

  constructor(public query: string) {}
}

export class NewThread {
  static readonly type = '[Chat] New thread';

  constructor(public assetId: string) {}
}

export class ClearChat {
  static readonly type = '[Chat] Clear chat';
}

export class ChunkReceived {
  static readonly type = '[Chat] ChunkReceived';

  constructor(
    public chunkIndex: number,
    public text: string | undefined,
    public isCompleted: boolean
  ) {}
}

export class CitationReceived {
  static readonly type = '[Chat] CitationReceived';

  constructor(
    public title: string,
    public url: string
  ) {}
}

export class ChunksCompleted {
  static readonly type = '[Chat] ChunksCompleted';
}

export class FileReady {
  static readonly type = '[Chat] FileReady';

  constructor(
    public fileName: string,
    public url: string
  ) {}
}
