<div class="h-full w-full flex" pFocusTrap>
  <div class="chat-container w-full">
    <div
      *ngIf="messages().length == 0"
      class="w-full h-full flex justify-content-center"
    >
      <div class="flex flex-column justify-content-center row-gap-2">
        <div class="flex justify-content-center">
          <h3>How can I help you?</h3>
        </div>
        <div class="flex justify-content-center">
          I’m here to help you manage and explore your cyber-physical assets.
        </div>

        <div class="flex justify-content-center">
          Ask me about asset details, relationships, or anything related to your
          system's design and data—and we'll get started!
        </div>
      </div>
    </div>

    <div class="messages flex flex-column gap-5" #messagesElm>
      <div
        class="message flex"
        [ngClass]="{
          chatgpt: message.type == ChatMessageType.Bot,
          user: message.type == ChatMessageType.User
        }"
        *ngFor="
          let message of messages();
          let i = index;
          trackBy: trackByMessages
        "
      >
        <img
          *ngIf="message.type == ChatMessageType.Bot"
          alt="Chat logo"
          style="display: inline"
          height="24"
          width="25"
          src="assets/layout/images/logoicon.svg"
        />
        <span class="ml-2">
          <span class="received-message" [innerHtml]="message.text"></span>
          <span
            class="receiving-indicator"
            *ngIf="
              message.type == ChatMessageType.Bot &&
              receivingChunks() &&
              i == messages().length - 1
            "
          ></span>

          <div class="files" *ngIf="message.files.length > 0">
            <span
              class="file"
              *ngFor="let file of message.files; let i = index"
            >
              <a href="javascript:void(0)" target="_blank">
                <span>Download {{ file.fileName }}</span>
              </a>
            </span>
          </div>

          <div
            class="citations"
            *ngIf="message.citations.length > 0 && !receivingChunks()"
          >
            <span
              class="citation"
              *ngFor="let citation of message.citations; let i = index"
              ><span class="citation-index">[{{ i + 1 }}]</span>&nbsp;
              <a [href]="'asset/' + citation.url" target="_blank">{{
                citation.title
              }}</a>
            </span>
          </div>
        </span>
      </div>
    </div>
    <div class="flex p-2">
      <div class="flex w-full justify-content-center">
        <form [formGroup]="formGroup" (ngSubmit)="onSubmit($event)">
          <input
            [autofocus]="true"
            #searchInput
            data-testid="chat-input"
            pInputText
            formControlName="message"
            class="flex search-input search-input"
            placeholder="Type your message..."
            autocomplete="off"
          />
          <button
            class="send-button"
            data-testid="chat-send"
            size="small"
            type="submit"
            [disabled]="!formGroup.valid"
            pButton
            icon="pi pi-arrow-up"
          ></button>
        </form>
      </div>
    </div>
  </div>
</div>
