import {
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  inject,
  EventEmitter,
  model,
  effect
} from '@angular/core';
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RouterOutlet
} from '@angular/router';
import { filter, map, Subscription } from 'rxjs';
import { MenuService } from './app.menu.service';
import { LayoutService } from './service/app.layout.service';
import { AppSidebarComponent } from './app.sidebar.component';
import { FetchUser } from '../../shared/app.actions';
import { Store } from '@ngxs/store';
import { NgClass, NgIf } from '@angular/common';
import { AppTopBarComponent } from './app.topbar.component';
import { AppFooterComponent } from './app.footer.component';
import { ButtonDirective } from 'primeng/button';
import { ChatComponent } from '../chat/chat.component';
import { SidebarModule } from 'primeng/sidebar';
import { ChatState } from '../chat/chat.state';
import { ChatQuery, ClearChat, NewThread } from '../chat/chat.actions';
import { TooltipModule } from 'primeng/tooltip';
import { TenantService } from '../shared/tenant.service';

@Component({
  selector: 'app-layout',
  templateUrl: './app.layout.component.html',
  styleUrls: ['./app.layout.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    AppTopBarComponent,
    RouterOutlet,
    AppFooterComponent,
    ChatComponent,
    SidebarModule,
    TooltipModule,
    ButtonDirective
  ]
})
export class AppLayoutComponent implements OnDestroy, OnInit {
  private readonly menuService = inject(MenuService);
  readonly layoutService = inject(LayoutService);
  readonly renderer = inject(Renderer2);
  readonly router = inject(Router);
  private readonly store = inject(Store);
  private readonly tenantService = inject(TenantService);
  private readonly subscription: Subscription = new Subscription();

  overlayMenuOpenSubscription: Subscription | undefined;
  protected messages = this.store.selectSignal(ChatState.messages);
  protected receivingChunks = this.store.selectSignal(
    ChatState.receivingChunks
  );
  protected onNewThread = new EventEmitter<void>();

  menuOutsideClickListener?: () => void;

  @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;

  public hasSelectedAssetId = model(false);

  tenant$ = this.tenantService.getTenant();

  constructor() {
    this.overlayMenuOpenSubscription =
      this.layoutService.overlayOpen$.subscribe(() => {
        if (!this.menuOutsideClickListener) {
          this.menuOutsideClickListener = this.renderer.listen(
            'document',
            'click',
            (event: Event) => {
              const target = <HTMLElement>event.target;
              const parent = <HTMLElement>target.parentNode;

              const isOutsideClicked = !(
                this.appSidebar.el.nativeElement.isSameNode(target) ||
                this.appSidebar.el.nativeElement.contains(target) ||
                target.classList.contains('p-trigger') ||
                parent.classList.contains('p-trigger')
              );

              if (isOutsideClicked) {
                this.layoutService.state.profileSidebarVisible = false;
                this.layoutService.state.overlayMenuActive = false;
                this.layoutService.state.staticMenuMobileActive = false;
                this.layoutService.state.menuHoverActive = false;
                this.menuService.reset();
                this.menuOutsideClickListener?.();
                this.menuOutsideClickListener = undefined;
                this.unblockBodyScroll();
              } else if (this.layoutService.state.staticMenuMobileActive) {
                this.blockBodyScroll();
              }
            }
          );
        }
      });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.unblockBodyScroll();
      });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe(() => {
        this.layoutService.displayHeader();
      });

    effect(() => {
      const hasSelectedAssetId = this.hasSelectedAssetId();
      if (!hasSelectedAssetId) this.store.dispatch(new ClearChat());
    });
  }

  async ngOnInit(): Promise<void> {
    this.store.dispatch(new FetchUser());

    this.subscription.add(
      this.store
        .select(ChatState.assetId)
        .pipe(map((assetId) => assetId !== null))
        .subscribe((hasSelectedAssetId) => {
          this.hasSelectedAssetId.set(hasSelectedAssetId);
        })
    );
  }

  blockBodyScroll(): void {
    if (document.body.classList != undefined) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  }

  unblockBodyScroll(): void {
    if (document.body.classList != undefined) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          '(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)',
          'gi'
        ),
        ' '
      );
    }
  }

  get containerClass() {
    return {
      'layout-theme-light': this.layoutService.config.colorScheme === 'light',
      'layout-theme-dark': this.layoutService.config.colorScheme === 'dark',
      'layout-overlay': this.layoutService.config.menuMode === 'overlay',
      'layout-static': this.layoutService.config.menuMode === 'static',
      'layout-slim': this.layoutService.config.menuMode === 'slim',
      'layout-horizontal': this.layoutService.config.menuMode === 'horizontal',
      'layout-static-inactive': this.layoutService.config.menuMode === 'static',
      'layout-overlay-active': this.layoutService.state.overlayMenuActive,
      'layout-mobile-active': this.layoutService.state.staticMenuMobileActive,
      'p-input-filled': this.layoutService.config.inputStyle === 'filled',
      'p-ripple-disabled': !this.layoutService.config.ripple
    };
  }

  showHeader() {
    return this.layoutService.showHeader;
  }

  ngOnDestroy() {
    if (this.overlayMenuOpenSubscription) {
      this.overlayMenuOpenSubscription.unsubscribe();
    }

    if (this.menuOutsideClickListener) {
      this.menuOutsideClickListener();
    }
  }

  onQuery($event: string) {
    this.store.dispatch(new ChatQuery($event));
  }

  newThread() {
    const assetId = this.store.selectSnapshot(ChatState.assetId);
    this.store.dispatch(new NewThread(assetId!));
    this.onNewThread.emit();
  }
}
