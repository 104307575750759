import { Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { LoginComponent } from './login/login.component';
import { ImportAssetsComponent } from './import/import-assets/import-assets.component';
import { AuthGuard } from './shared/auth-guard.service';
import { ImportApplicationRequirementsComponent } from './import/import-application-requirements/import-application-requirements.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { provideStates } from '@ngxs/store';
import { AssetState } from '../shared/asset.state';
import { AssetAttachmentState } from '../shared/asset-attachment.state';
import { MasterDataState } from '../shared/master-data.state';
import { ApplicationRequirementState } from '../shared/application-requirement.state';
import { ApplicationImplementationState } from '../shared/application-implementation.state';
import { ApplicationImplementationTypeState } from '../shared/application-implementation-type.state';
import { ComputingPlatformState } from '../shared/computing-platform.state';
import { ComputingPlatformApplicationImplementationState } from '../shared/computing-platform-application-implementation.state';
import { ComputingPlatformHierarchyAttachmentState } from '../shared/computing-platform-hierarchy-attachment.state';
import { ApplicationImplementationTypeAttachmentState } from '../shared/application-implementation-type-attachment.state';
import { ApplicationRequirementAttachmentState } from '../shared/application-requirement-attachment.state';
import { ChatState } from './chat/chat.state';
import { withStorageFeature } from '@ngxs/storage-plugin';
export const APP_ROUTES: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: '',
    component: AppLayoutComponent,
    providers: [
      provideStates(
        [ChatState],
        withStorageFeature(['chat.messages', 'chat.threadId'])
      )
    ],
    children: [
      {
        path: '',
        redirectTo: 'asset',
        pathMatch: 'full'
      },
      {
        path: 'settings',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./settings/settings.routes').then((o) => o.ROUTES)
      },
      {
        path: 'asset-function-type',
        canLoad: [AuthGuard],
        data: {
          permission: 'MasterData'
        },
        loadChildren: () =>
          import('./asset-function-type/asset-function-type.routing').then(
            (m) => m.ROUTES
          )
      },
      {
        path: 'application-requirement-type',
        canLoad: [AuthGuard],
        data: {
          permission: 'MasterData'
        },
        loadChildren: () =>
          import(
            './application-requirement-type/application-requirement-type.routing'
          ).then((m) => m.ROUTES)
      },
      {
        path: 'application-implementation-type',
        canLoad: [AuthGuard],
        data: {
          permission: 'MasterData'
        },
        loadChildren: () =>
          import(
            './application-implementation-type/application-implementation-type.routing'
          ).then((m) => m.ROUTES),
        providers: [
          provideStates([
            ApplicationImplementationTypeState,
            ApplicationImplementationTypeAttachmentState
          ])
        ]
      },
      {
        path: 'computing-platform',
        canLoad: [AuthGuard],
        data: {
          permission: 'MasterData'
        },
        loadChildren: () =>
          import('./computing-platform/computing-platform.routing').then(
            (m) => m.ROUTES
          ),
        providers: [
          provideStates([
            ApplicationImplementationTypeState,
            ComputingPlatformApplicationImplementationState,
            ComputingPlatformState,
            ComputingPlatformHierarchyAttachmentState
          ])
        ]
      },
      {
        path: 'asset',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./asset/asset.routing').then((m) => m.ROUTES),
        providers: [
          provideStates([
            AssetState,
            AssetAttachmentState,
            MasterDataState,
            ApplicationRequirementState,
            ApplicationImplementationState,
            ApplicationImplementationTypeState,
            ComputingPlatformState,
            ApplicationRequirementAttachmentState
          ])
        ]
      },
      {
        path: 'search',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./search/search.routing').then((m) => m.ROUTES)
      },
      {
        path: 'import-assets',
        canLoad: [AuthGuard],
        data: {
          permission: 'ReadWrite'
        },
        component: ImportAssetsComponent
      },
      {
        path: 'import-application-requirements',
        canLoad: [AuthGuard],
        component: ImportApplicationRequirementsComponent,
        data: {
          permission: 'ReadWrite'
        }
      }
    ]
  }
];
