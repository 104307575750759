<div class="layout-wrapper" [ngClass]="containerClass">
  <app-topbar *ngIf="showHeader()"></app-topbar>
  <div
    [ngClass]="{ 'layout-main-no-header': !showHeader() }"
    class="layout-main-container"
  >
    <div class="layout-main">
      <router-outlet></router-outlet>
    </div>
    <app-footer *ngIf="showHeader()"></app-footer>
  </div>
  <div class="layout-mask"></div>
</div>

<p-sidebar
  [(visible)]="hasSelectedAssetId"
  #sidebar
  [blockScroll]="true"
  styleClass="chat-bar"
  position="bottom"
  [closeOnEscape]="true"
  [fullScreen]="true"
>
  <ng-template pTemplate="header">
    <button
      pButton
      icon="pi pi-pen-to-square"
      [text]="true"
      [rounded]="true"
      *ngIf="messages().length > 0"
      (click)="newThread()"
      label="New discussion"
      severity="contrast"
    ></button>
  </ng-template>
  <app-chat
    [newThread]="onNewThread"
    [onShow]="sidebar.onShow"
    [messages]="messages()"
    (query)="onQuery($event)"
    [receivingChunks]="receivingChunks()"
  ></app-chat>
</p-sidebar>
