import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { inject, Injectable } from '@angular/core';
import {
  ChatQuery,
  ChunkReceived,
  ChunksCompleted,
  CitationReceived,
  ClearChat,
  FileReady,
  NewThread
} from './chat.actions';
import { SignalrService } from '../shared/signalr.service';
import { CybersortGPTClient } from '../../clients/apiClients';
import { marked } from 'marked';
import { ChatMessage, ChatMessageType } from './models/chat-message';
import { MessageChunk } from './models/message-chunk';
import { Citation } from './models/citation';
import { tap } from 'rxjs';
import posthog from 'posthog-js';
import { DownloadFile } from './models/download-file';

export class ChatStateModel {
  messages: ChatMessage[] = [];
  currentMessage: MessageChunk[] | null = null;
  currentCitations: Citation[] = [];
  files: DownloadFile[] = [];
  receivingChunks: boolean = false;
  threadId: string | null = null;
  assetId: string | null = null;
}

@State<ChatStateModel>({
  name: 'chat',
  defaults: {
    messages: [],
    currentMessage: null,
    currentCitations: [],
    files: [],
    receivingChunks: false,
    threadId: null,
    assetId: null
  }
})
@Injectable()
export class ChatState {
  private readonly signalRService = inject(SignalrService);
  private readonly gptClient = inject(CybersortGPTClient);
  private readonly store = inject(Store);

  constructor() {
    this.signalRService.register<any>('GptData').subscribe((data) => {
      if (Array.isArray(data)) {
        data.forEach((d) => this.processMessage(d));
      } else {
        this.processMessage(data);
      }
    });
  }

  processMessage(data: any) {
    if (data.type == 'ChunkUpdate' || data.type == 'ChunkUpdateDone') {
      this.store.dispatch(
        new ChunkReceived(data.chunkIndex, data.text, data.isSuccess || false)
      );
    } else if (data.type == 'CitationUpdate') {
      this.store.dispatch(
        new CitationReceived(data.sourceName, data.attachmentId)
      );
    } else if (data.type == 'FileReady') {
      this.store.dispatch(new FileReady(data.fileName, data.url));
    }
  }

  @Action(NewThread)
  async newThread(ctx: StateContext<ChatStateModel>, action: NewThread) {
    posthog.capture('new_thread');
    ctx.setState({
      messages: [],
      currentMessage: null,
      currentCitations: [],
      files: [],
      receivingChunks: false,
      threadId: null,
      assetId: action.assetId
    });
  }

  @Action(ClearChat)
  async clearChat(ctx: StateContext<ChatStateModel>) {
    ctx.setState({
      messages: [],
      currentMessage: null,
      currentCitations: [],
      files: [],
      receivingChunks: false,
      threadId: null,
      assetId: null
    });
  }

  @Action(ChunkReceived)
  async chunkReceived(
    ctx: StateContext<ChatStateModel>,
    action: ChunkReceived
  ) {
    const state = ctx.getState();
    const currentMessage = [...(state.currentMessage || [])];
    currentMessage[action.chunkIndex] = action;
    ctx.patchState({
      currentMessage
    });

    const newState = ctx.getState();

    if (this.allChunksAreReceived(newState.currentMessage)) {
      ctx.dispatch(new ChunksCompleted());
    }
  }

  private allChunksAreReceived(chunks: MessageChunk[] | null) {
    if (chunks && chunks.length > 0 && chunks[chunks.length - 1].isCompleted) {
      for (const element of chunks) {
        if (!element) return false;
      }
      return true;
    }
    return false;
  }

  @Action(CitationReceived)
  async citationReceived(
    ctx: StateContext<ChatStateModel>,
    action: CitationReceived
  ) {
    const state = ctx.getState();
    const currentCitations = [...state.currentCitations];
    currentCitations.push(new Citation(action.title, action.url));
    ctx.patchState({
      currentCitations
    });
  }

  @Action(FileReady)
  async fileReady(ctx: StateContext<ChatStateModel>, action: FileReady) {
    const state = ctx.getState();
    const files = [...state.files];
    files.push(new DownloadFile(action.fileName, action.url));
    ctx.patchState({
      files
    });
  }

  @Action(ChunksCompleted)
  async chunksCompleted(ctx: StateContext<ChatStateModel>) {
    const state = ctx.getState();
    if (state.currentMessage) {
      const currentMessage = [...state.currentMessage];
      const currentCitations = [...state.currentCitations];
      const files = [...state.files];
      const mappedMessage = currentMessage.map((m) => m.text);
      const joinedMessage = mappedMessage.join('');
      const messages = [
        ...state.messages,
        new ChatMessage(
          ChatState.formatMessage(joinedMessage),
          ChatMessageType.Bot,
          state.messages.length,
          currentCitations,
          files
        )
      ];
      ctx.patchState({
        messages,
        currentMessage: null,
        receivingChunks: false,
        currentCitations: [],
        files: []
      });
    }
  }

  @Action(ChatQuery)
  async query(ctx: StateContext<ChatStateModel>, action: ChatQuery) {
    const state = ctx.getState();
    const messages = [
      ...state.messages,
      new ChatMessage(
        action.query,
        ChatMessageType.User,
        state.messages.length,
        [],
        []
      )
    ];
    ctx.patchState({
      messages,
      currentMessage: [],
      receivingChunks: true
    });

    posthog.capture('chat_query', {
      query: action.query,
      threadId: state.threadId
    });

    return this.gptClient
      .getGptData(action.query, state.assetId!, state.threadId)
      .pipe(
        tap((o) => {
          ctx.patchState({
            threadId: o.threadId
          });

          if (o.errorMessage) {
            ctx.dispatch(new ChunkReceived(0, o.errorMessage, false));
            ctx.dispatch(new ChunksCompleted());
          }
        })
      );
  }

  @Selector()
  static messages(state: ChatStateModel) {
    if (state.currentMessage) {
      const currentMessage = [...state.currentMessage];
      const mappedMessage = currentMessage
        .filter((o) => o && !o.isCompleted)
        .map((m) => m.text);
      const joinedMessage = mappedMessage.join('');
      return [
        ...state.messages,
        new ChatMessage(
          ChatState.formatMessage(joinedMessage),
          ChatMessageType.Bot,
          state.messages.length,
          state.currentCitations,
          state.files
        )
      ];
    }
    return state.messages;
  }

  static formatMessage(message: string) {
    const markedMessage = <string>marked.parse(message);
    const regex = /\[doc(\d+)\]/g;
    return markedMessage.replace(regex, '[$1]');
  }

  @Selector()
  static receivingChunks(state: ChatStateModel) {
    return state.receivingChunks;
  }

  @Selector()
  static assetId(state: ChatStateModel) {
    return state.assetId;
  }
}
