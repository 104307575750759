import { Citation } from './citation';
import { DownloadFile } from './download-file';

export class ChatMessage {
  constructor(
    public text: string,
    public type: ChatMessageType,
    public index: number,
    public citations: Citation[],
    public files: DownloadFile[]
  ) {}
}

export enum ChatMessageType {
  User,
  Bot
}
